.UserHome {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.UserHome h2 {
  text-align: center;
}
.Debt {
  text-align: left;
  color: red;
  padding-top: 10px;
  padding-bottom: 15px;
}
.processing {
	text-align: center;
	width: 100%;
}
.RedRow {
	background-color: #ff6666;
}
.GreyRow {
	background-color: #e6e6e6;
}