.BankAccInfo {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.BankAccInfo h2 {
  text-align: center;
}
.BankAccInfo #note {
  color: red;
  padding-top: 5px;
}