.invalid-feedback-to-display {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.recaptcha {
	width: 100%;
}
.recaptcha div {
	width: 100%;
}
.recaptcha div div {
	margin: auto;
}

.submitBtn {
	padding-top: 10px;
	padding-bottom: 10px;
}
