.MemberBalance {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}
.Debt {
  color: red;
}
.NoDebt {
  color: green;
}