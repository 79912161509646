.PeopleList h2 {
  text-align: center;
}
.PeopleList {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.PeopleRow {
	padding-bottom: 5px;
}
.AddButton{
	padding-top: 30px;
	vertical-align: bottom;
}

.submitBtn{
	text-align: center;
}
.modeText{
	text-align: center;
	font-size: 30px;
}